import styled from "@emotion/styled"
import React, { useContext, useEffect, useRef, useState } from "react"
import CrossIcon from "../../assets/icons/cross-cart.svg"
import CheckmarkIcon from "../../assets/icons/checkmark.svg"
import PlusIcon from "../../assets/icons/plus-circle.svg"
import MinusIcon from "../../assets/icons/minus-circle.svg"
import { InterfaceContext } from "../../context/interface-context"
import { useFilterContext } from "../../context/filter-context"

// FILTERS
const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 400px;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  border-right: ${({ theme }) => theme.borders.thin};
  color: ${({ theme }) => theme.colors.darkGreen};
  position: fixed;
  z-index: 2002;
  transition: transform 0.5s ease-out;
  transform: translateX(-100%);

  ${(props) =>
    props.show &&
    `
  transform: translateX(0);
  `}

  svg.discount-close-icon {
    height: 12px;
  }
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FiltersHeader = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.height[1]}px;
  padding: 10px 20px;
  border-bottom: ${({ theme }) => theme.borders.thin};
`

const FiltersHeading = styled.h6`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  font-weight: normal;
`

const FiltersClose = styled.div`
  cursor: pointer;
`

const FiltersClear = styled.button`
  padding: 0;
  margin-left: auto;
  margin-right: 30px;
  background: transparent;
  border: none;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.darkGreen};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

const FiltersBody = styled.div`
  flex-grow: 1;
  padding: 0 20px 20px 20px;
  overflow: auto;
`

const FiltersCheckbox = styled.div`
  position: relative;

  & + & {
    margin-top: 10px;
  }
`

const FiltersCheckboxInput = styled.input`
  display: none;
`

const FiltersCheckboxLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  cursor: pointer;
  transition: color 0.2s;

  &:before {
    content: "";
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    position: relative;
    top: -1px;
    border: ${({ theme }) => theme.borders.thin};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightGreen};
  }
`

const FiltersCheckboxCheckmark = styled(CheckmarkIcon)`
  position: absolute;
  left: 8px;
  top: 8px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;

  input${FiltersCheckboxInput}:checked + label${FiltersCheckboxLabel} + & {
    opacity: 1;
  }
`

const FiltersFooter = styled.div`
  flex-shrink: 0;
  padding: 20px;
`

const Accordion = styled.div``

const AccordionItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.opaqueGreen};
`

const AccordionItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  cursor: pointer;
`

const AccordionItemBody = styled.div`
  padding-bottom: 20px;
`

const FiltersShowResults = styled.button`
  display: block;
  width: 100%;
  padding: 13px 20px;
  background-color: ${({ theme }) => theme.colors.darkGreen};
  border: none;
  border-radius: 26px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGreen};
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    min-width: 180px;
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }
`

const FilterDrawer = () => {
  const filterRef = useRef()
  const [accordionActive, setAccordionActive] = useState("")
  const { isFilterOpen, toggleFilter } = useContext(InterfaceContext)
  const {
    filterSelected,
    filterOptions,

    getFilterCount,

    filterUsageClickHandler,
    filterCategoryClickHandler,
    filterColorClickHandler,
    filterSizeClickHandler,
    filterApplyClickHandler,
    filterClearClickHandler,
  } = useFilterContext()

  const escFunction = (event) => {
    if (event.key === "Escape") {
      toggleFilter()
    }
  }

  useEffect(() => {
    if (isFilterOpen) {
      document.addEventListener("keydown", escFunction, true)
    }

    return () => {
      document.removeEventListener("keydown", escFunction, true)
    }
  }, [isFilterOpen])

  const handleClick = (e) => {
    if (filterRef.current && !filterRef.current.contains(e.target)) {
      // inside click
      toggleFilter()
    }
  }

  useEffect(() => {
    // add when mounted
    if (isFilterOpen) {
      document.addEventListener("mousedown", handleClick, true)
    }
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick, true)
    }
  }, [isFilterOpen])

  return (
    <FiltersContainer ref={filterRef} show={isFilterOpen}>
      <FiltersWrapper>
        <FiltersHeader>
          <FiltersHeading>Filters</FiltersHeading>
          {getFilterCount() > 0 && (
            <FiltersClear onClick={filterClearClickHandler}>
              Clear all
            </FiltersClear>
          )}
          <FiltersClose>
            <CrossIcon onClick={() => toggleFilter()} />
          </FiltersClose>
        </FiltersHeader>
        <FiltersBody>
          <Accordion>
            {filterOptions.categories.length > 0 && (
              <AccordionItem>
                <AccordionItemHeader
                  onClick={() =>
                    setAccordionActive(
                      accordionActive === "categories" ? "" : "categories"
                    )
                  }
                >
                  Categories{" "}
                  {accordionActive === "categories" ? (
                    <MinusIcon />
                  ) : (
                    <PlusIcon />
                  )}
                </AccordionItemHeader>
                {accordionActive === "categories" && (
                  <AccordionItemBody>
                    {filterOptions.categories.map((e) => {
                      return (
                        <FiltersCheckbox key={e.id}>
                          <FiltersCheckboxInput
                            type="checkbox"
                            value={e.id}
                            id={e.id}
                            checked={filterSelected.categories.includes(e.id)}
                            onChange={() => filterCategoryClickHandler(e.id)}
                          />
                          <FiltersCheckboxLabel htmlFor={e.id}>
                            {" "}
                            {e.name}
                          </FiltersCheckboxLabel>
                          <FiltersCheckboxCheckmark />
                        </FiltersCheckbox>
                      )
                    })}
                  </AccordionItemBody>
                )}
              </AccordionItem>
            )}

            {filterOptions.colors.length > 0 && (
              <AccordionItem>
                <AccordionItemHeader
                  onClick={() =>
                    setAccordionActive(
                      accordionActive === "colors" ? "" : "colors"
                    )
                  }
                >
                  Colors{" "}
                  {accordionActive === "colors" ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemHeader>
                {accordionActive === "colors" && (
                  <AccordionItemBody>
                    {filterOptions.colors.map((e) => {
                      return (
                        <FiltersCheckbox key={e}>
                          <FiltersCheckboxInput
                            type="checkbox"
                            id={e}
                            checked={filterSelected.colors.includes(e)}
                            onChange={() => filterColorClickHandler(e)}
                          />
                          <FiltersCheckboxLabel htmlFor={e}>
                            {" "}
                            {e}
                          </FiltersCheckboxLabel>
                          <FiltersCheckboxCheckmark />
                        </FiltersCheckbox>
                      )
                    })}
                  </AccordionItemBody>
                )}
              </AccordionItem>
            )}

            {filterOptions.sizes.length > 0 && (
              <AccordionItem>
                <AccordionItemHeader
                  onClick={() =>
                    setAccordionActive(
                      accordionActive === "sizes" ? "" : "sizes"
                    )
                  }
                >
                  Sizes{" "}
                  {accordionActive === "sizes" ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemHeader>
                {accordionActive === "sizes" && (
                  <AccordionItemBody>
                    {filterOptions.sizes.map((e) => {
                      return (
                        <FiltersCheckbox key={e}>
                          <FiltersCheckboxInput
                            type="checkbox"
                            id={e}
                            checked={filterSelected.sizes.includes(e)}
                            onChange={() => filterSizeClickHandler(e)}
                          />
                          <FiltersCheckboxLabel htmlFor={e}>
                            {" "}
                            {e}
                          </FiltersCheckboxLabel>
                          <FiltersCheckboxCheckmark />
                        </FiltersCheckbox>
                      )
                    })}
                  </AccordionItemBody>
                )}
              </AccordionItem>
            )}

            {filterOptions.usage.length > 0 && (
              <AccordionItem>
                <AccordionItemHeader
                  onClick={() =>
                    setAccordionActive(
                      accordionActive === "usage" ? "" : "usage"
                    )
                  }
                >
                  Usage{" "}
                  {accordionActive === "usage" ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemHeader>
                {accordionActive === "usage" && (
                  <AccordionItemBody>
                    {filterOptions.usage.map((e) => {
                      return (
                        <FiltersCheckbox key={e.id}>
                          <FiltersCheckboxInput
                            type="checkbox"
                            value={e.id}
                            id={e.id}
                            checked={filterSelected.usage.includes(e.id)}
                            onChange={() => filterUsageClickHandler(e.id)}
                          />
                          <FiltersCheckboxLabel htmlFor={e.id}>
                            {" "}
                            {e.name}
                          </FiltersCheckboxLabel>
                          <FiltersCheckboxCheckmark />
                        </FiltersCheckbox>
                      )
                    })}
                  </AccordionItemBody>
                )}
              </AccordionItem>
            )}
          </Accordion>
        </FiltersBody>
        <FiltersFooter>
          <FiltersShowResults
            onClick={filterApplyClickHandler}
            disabled={!filterSelected.results}
          >
            Show results ({filterSelected.results})
          </FiltersShowResults>
        </FiltersFooter>
      </FiltersWrapper>
    </FiltersContainer>
  )
}

export default FilterDrawer
